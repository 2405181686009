import React from "react"
import Img from "gatsby-image"
import { graphql } from 'gatsby'
// import { FaBullseye } from 'react-icons/fa';

import Layout from "../components/layout"
import SEO from "../components/seo"

// const context = "our-mission"
const seoTitle = "Director's Message"

const DirectorsMessagePage = ({data}) => (
    <Layout>
        <SEO title={seoTitle}/>
        <div className="container">            
            <div className="row article-banner">
                <div className="col">
                    <Img fluid={data.bannerql.childImageSharp.fluid} />
                </div>
            </div>
            <div className="row article-heading">
                <div className="col">
                    <h3><span className="label label-primary">{data.markupql.edges[0].node.frontmatter.heading}</span></h3>
                </div>
            </div>            
            <div className="row article-text  alert alert-danger">
                <div className="col">
                    {/* <Img fixed={data.peopleql.childImageSharp.fixed} title={data.markupql.edges[0].node.frontmatter.imageTitle} Tag="span"/> */}
                    <span
                        dangerouslySetInnerHTML={{ __html: data.markupql.edges[0].node.html }}
                    >
                    </span>
                </div>
            </div>
        </div>
    </Layout>
)

export default DirectorsMessagePage

// export const query1 = graphql `
//     query {
//     bannerql: file(relativePath: {eq: "banners/directors-message-1.jpg"}) {
//         childImageSharp {
//             fluid(maxWidth: 1900) {
//                 ...GatsbyImageSharpFluid
//             }
//         }
//     }

//     markupql: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/directors-message/i"}}) {
//         edges {
//           node {
//             frontmatter {
//               heading
//               imageTitle
//             }
//             html
//           }
//         }
//     }

//     peopleql: file(relativePath: {eq: "people/director.jpg"}) {
//         childImageSharp {
//             fixed(width: 200) {
//                 ...GatsbyImageSharpFixed
//             }
//         }
//     }
// }
// `

export const query1 = graphql `
    query {
    bannerql: file(relativePath: {eq: "banners/coming-soon-1.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1900) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    markupql: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/coming-soon/i"}}) {
        edges {
          node {
            frontmatter {
              heading
            }
            html
          }
        }
      }
}
`